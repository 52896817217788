import { buildRequest } from '@/services/api';

const prefix = 'api/base';

const useBaseApi = () => ({
  /**
   * Resource
   */
  createResource: (resource) => buildRequest('post',
    `${prefix}/resource`, resource),

  updateResource: (id, resource) => buildRequest('put',
    `${prefix}/resource/${id}`, resource),

  deleteResource: (id) => buildRequest('delete',
    `${prefix}/resource/${id}`),

  fetchResource: (resourceId) => buildRequest('get',
    `${prefix}/resource/${resourceId}/show`),

  fetchPickerResources: () => buildRequest('get',
    `${prefix}/resource/index-picker`),

  fetchNewestInsurance: (resourceId) => buildRequest('get',
    `${prefix}/resource/get-last-insurance/${resourceId}`),

  fetchNewestService: (resourceId, type) => buildRequest('get',
    `${prefix}/resource/get-last-service/${resourceId}`, { type }),

  /**
   * Insurance
   */
  createInsurance: (insurance) => buildRequest('post',
    `${prefix}/insurance`, insurance),

  updateInsurance: (insuranceId, insurance) => buildRequest('post',
    `${prefix}/insurance/${insuranceId}`, insurance),

  deleteInsurance: (insuranceId) => buildRequest('delete',
    `${prefix}/insurance/${insuranceId}`),

  /**
   * Service
   */
  createService: (service) => buildRequest('post',
    `${prefix}/resource-service`, service),

  updateService: (serviceId, service) => buildRequest('put',
    `${prefix}/resource-service/${serviceId}`, service),

  deleteService: (serviceId, service) => buildRequest('delete',
    `${prefix}/resource-service/${serviceId}`, service),

  uploadServiceAttachment: (data) => buildRequest('post',
    `${prefix}/resource-service/media`, data),

  fetchService: (serviceId) => buildRequest('get',
    `${prefix}/resource-service/${serviceId}/show`),

  fetchPickerServiceTypes: () => buildRequest('get',
    `${prefix}/resource-service/types`),

  fetchServiceStatuses: () => buildRequest('get',
    `${prefix}/resource-service/status/index-picker`),

  /**
   * Transport
   */
  createTransport: (transport) => buildRequest('post',
    `${prefix}/transport`, transport),

  updateTransport: (transportId, transport) => buildRequest('put',
    `${prefix}/transport/${transportId}`, transport),

  deleteTransport: (transportId) => buildRequest('delete',
    `${prefix}/transport/${transportId}`),

  fetchTransport: (transportId) => buildRequest('get',
    `${prefix}/transport/${transportId}/show`),

  fetchTransportLogs: (transportId) => buildRequest('get',
    `${prefix}/transport/logs`, { auditableId: transportId }),

  indexTransportTypes: () => buildRequest('get',
    `${prefix}/transport-types`),

  indexTransport: () => buildRequest('get',
    `${prefix}/transport`),

  /**
   * Machine
   * TODO: Move these methods to resource
   */
  createMachine: (machine) => buildRequest('post',
    `${prefix}/resource`, machine),

  updateMachine: (id, machine) => buildRequest('put',
    `${prefix}/resource/${id}`, machine),

  deleteMachine: (machineId) => buildRequest('delete',
    `${prefix}/vehicle/${machineId}`),

  fetchMachine: (machineId) => buildRequest('get',
    `${prefix}/resource/${machineId}/show`),

  fetchPickerMachines: () => buildRequest('get',
    `${prefix}/resource/index-picker`),

  attachResourcesToResource: (data) => buildRequest('post',
    `${prefix}/resource/assign-resource`, data),

  assignMachineTool: (machineId, toolIds) => buildRequest('post',
    `${prefix}/resource/assign-resource`, { base_first_resource_id: machineId, base_second_resource_id: toolIds[0] }),

  unassignMachineTool: (machineId, toolIds) => buildRequest('patch',
    `${prefix}/vehicle/unassign-tools`, { vehicle_id: machineId, tool_ids: toolIds[0] }),

  /**
   * Equipment
   * TODO: Move these methods to resource
   */
  createEquipment: (equipment) => buildRequest('post',
    `${prefix}/tool`, equipment),

  updateEquipment: (id, equipment) => buildRequest('put',
    `${prefix}/tool/${id}`, equipment),

  deleteEquipment: (equipmentId) => buildRequest('delete',
    `${prefix}/tool/${equipmentId}`),

  fetchEquipment: (equipmentId) => buildRequest('get',
    `${prefix}/tool/${equipmentId}/show`),

  fetchPickerTools: () => buildRequest('get',
    `${prefix}/resource/index-picker`),

  /**
   * Resources
   */
  fetchResourceSummaries: (resourceIds) => buildRequest('get',
    `${prefix}/resource/status-meter-summary`, { resource_ids: resourceIds }),

  fetchScheduleEntries: (start, end, type) => buildRequest('get',
    `${prefix}/resource`, {
      integrations: {
        investment_resource_date_from: start,
        investment_resource_date_to: end,
      },
      columnFilters: {
        type_ids: [type],
      },
    }),

  /**
   * Status
   */
  fetchTypedStatuses: () => buildRequest('get',
    `${prefix}/statuses/index`),

  fetchStatusesIndex: () => buildRequest('get',
    `${prefix}/statuses/index`),

  /**
   * Category
   */
  fetchCategories: () => buildRequest('get',
    `${prefix}/category`),

  fetchIndexCategories: () => buildRequest('get',
    `${prefix}/category/index`),

  createCategory: (category) => buildRequest('post',
    `${prefix}/category`, category),

  updateCategory: (category) => buildRequest('put',
    `${prefix}/category/${category.id}`, category),

  deleteCategory: (categoryId) => buildRequest('delete',
    `${prefix}/category/${categoryId}`),

  /**
   * Producer
   */
  fetchProducers: () => buildRequest('get',
    `${prefix}/producer/index`),

  createProducer: (producer) => buildRequest('post',
    `${prefix}/producer`, producer),

  updateProducer: (producer) => buildRequest('post',
    `${prefix}/producer/${producer.id}`, producer),

  deleteProducer: (producerId) => buildRequest('delete',
    `${prefix}/producer/${producerId}`),

  /**
   * Producer Models
   */
  fetchProducersModels: () => buildRequest('get',
    `${prefix}/producer-model/index`),

  createProducersModels: (producer) => buildRequest('post',
    `${prefix}/producer-model`, producer),

  updateProducersModels: (producer) => buildRequest('put',
    `${prefix}/producer-model/${producer.id}`, producer),

  deleteProducersModels: (producerId) => buildRequest('delete',
    `${prefix}/producer-model/${producerId}`),

  /**
   * Group
   */
  fetchGroups: () => buildRequest('get',
    `${prefix}/group/index`),

  /**
   * Work Types
   */
  fetchWorkTypes: () => buildRequest('get',
    `${prefix}/work-type/index`),

  /**
   * Reports
   */
  createMachineReport: (report) => buildRequest('post',
    `${prefix}/resource-report`, report),

  createMultipleMachineReports: (reports) => buildRequest('post',
    `${prefix}/resource-report/store-many`, { base_resource_reports: reports }),

  updateMachineReport: (report) => buildRequest('put',
    `${prefix}/resource-report/${report.id}`, report),

  deleteMachineReport: (report) => buildRequest('delete',
    `${prefix}/resource-report/${report.id}`),

  fetchMthReport: (resourceId) => buildRequest('get',
    `${prefix}/resource-report/index-statistics/${resourceId}`),

  fetchMthReports: (params) => buildRequest('get',
    `${prefix}/resource-report/index`, params),

  /**
   * Technologies
   */
  fetchTechnologies: () => buildRequest('get',
    `${prefix}/technology`),

  createTechnology: (technology) => buildRequest('post',
    `${prefix}/technology`, technology),

  updateTechnology: (technology) => buildRequest('put',
    `${prefix}/technology/${technology.id}`, technology),

  deleteTechnology: (technologyId) => buildRequest('delete',
    `${prefix}/technology/${technologyId}`),

  /**
   * TODO: Current refactor subject
   * Resource
   */
  deleteAttachment: (attachmentId) => buildRequest('delete',
    `${prefix}/media/${attachmentId}`),

  /**
   * Extra Data
   */
  fetchExtraFieldValues: () => buildRequest('get', `${prefix}/field-value/index`),

  /**
   * Documents/Protocols
   */
  createHandoverProtocol: (document) => buildRequest('post',
    `${prefix}/document/render/handover-protocol`, document),

  createReturnProtocol: (document) => buildRequest('post',
    `${prefix}/document/render/return-protocol`, document),
});

export default useBaseApi;
