import BaseFeedback from '@feedback/module/base/BaseFeedback';

/**
 * Defined Feedback objects.
 *
 * Currently it looks messy, but the whole point of
 * defining each error separately (instead of using plain i18n)
 * is to allow further changes to the Feedback structure, e.g.
 * types, actions, custom templates and more settings.
 *
 * TODO: break this down into separate files whenever the Feedback
 *       logic will be ready
 *
 * After adding a feedback instance below, remember to add the proper
 * key to i18n
 */

/** Negative Feedbacks */
export const CreateResourceError = new BaseFeedback(
  'CreateResourceError',
  false,
);
export const UpdateResourceError = new BaseFeedback(
  'UpdateResourceError',
  false,
);
export const DeleteResourceError = new BaseFeedback(
  'DeleteResourceError',
  false,
);

export const CreateInsuranceError = new BaseFeedback(
  'CreateInsuranceError',
  false,
);
export const UpdateInsuranceError = new BaseFeedback(
  'UpdateInsuranceError',
  false,
);
export const DeleteInsuranceError = new BaseFeedback(
  'DeleteInsuranceError',
  false,
);

export const createHandoverProtocolError = new BaseFeedback(
  'createHandoverProtocolError',
  false,
);

export const createReturnProtocolError = new BaseFeedback(
  'createReturnProtocolError',
  false,
);

export const CreateTransportError = new BaseFeedback(
  'CreateTransportError',
  false,
);
export const UpdateTransportError = new BaseFeedback(
  'UpdateTransportError',
  false,
);
export const DeleteTransportError = new BaseFeedback(
  'DeleteTransportError',
  false,
);
export const FetchTransportError = new BaseFeedback(
  'FetchTransportError',
  false,
);

export const CreateServiceError = new BaseFeedback('CreateServiceError', false);
export const UpdateServiceError = new BaseFeedback('UpdateServiceError', false);
export const DeleteServiceError = new BaseFeedback('DeleteServiceError', false);
export const FetchServiceError = new BaseFeedback('FetchServiceError', false);

export const CreateCategoryError = new BaseFeedback(
  'CreateCategoryError',
  false,
);
export const UpdateCategoryError = new BaseFeedback(
  'UpdateCategoryError',
  false,
);
export const DeleteCategoryError = new BaseFeedback(
  'DeleteCategoryError',
  false,
);

export const CreateProducerError = new BaseFeedback(
  'CreateProducerError',
  false,
);
export const UpdateProducerError = new BaseFeedback(
  'UpdateProducerError',
  false,
);
export const DeleteProducerError = new BaseFeedback(
  'DeleteProducerError',
  false,
);

export const CreateProducerModelError = new BaseFeedback(
  'CreateProducerModelError',
  false,
);
export const UpdateProducerModelError = new BaseFeedback(
  'UpdateProducerModelError',
  false,
);
export const DeleteProducerModelError = new BaseFeedback(
  'DeleteProducerModelError',
  false,
);

export const CreateMachineError = new BaseFeedback('CreateMachineError', false);
export const UpdateMachineError = new BaseFeedback('UpdateMachineError', false);
export const DeleteMachineError = new BaseFeedback('DeleteMachineError', false);
export const FetchPickerMachinesError = new BaseFeedback(
  'FetchPickerMachinesError',
  false,
);

export const CreateEquipmentError = new BaseFeedback(
  'CreateEquipmentError',
  false,
);
export const UpdateEquipmentError = new BaseFeedback(
  'UpdateEquipmentError',
  false,
);
export const DeleteEquipmentError = new BaseFeedback(
  'DeleteEquipmentError',
  false,
);

export const FetchStatusesError = new BaseFeedback('FetchStatusesError', false);
export const FetchCategoriesError = new BaseFeedback(
  'FetchCategoriesError',
  false,
);
export const FetchTechnologiesError = new BaseFeedback(
  'FetchTechnologiesError',
  false,
);
export const FetchProducersError = new BaseFeedback(
  'FetchProducersError',
  false,
);
export const FetchProducersModelsError = new BaseFeedback(
  'FetchProducersModelsError',
  false,
);
export const FetchGroupsError = new BaseFeedback('FetchGroupsError', false);
export const FetchWorkTypesError = new BaseFeedback(
  'FetchWorkTypesError',
  false,
);

export const CreateMachineReportError = new BaseFeedback(
  'CreateMachineReportError',
  false,
);
export const CreateMultipleMachineReportsError = new BaseFeedback(
  'CreateMultipleMachineReportsError',
  false,
);
export const UpdateMachineReportError = new BaseFeedback(
  'UpdateMachineReportError',
  false,
);
export const DeleteMachineReportError = new BaseFeedback(
  'DeleteMachineReportError',
  false,
);

export const AssignMachineToolError = new BaseFeedback(
  'AssignMachineToolError',
  false,
);
export const UnassignMachineToolError = new BaseFeedback(
  'UnassignMachineToolError',
  false,
);

export const CreateTechnologyError = new BaseFeedback(
  'CreateTechnologyError',
  false,
);
export const UpdateTechnologyError = new BaseFeedback(
  'UpdateTechnologyError',
  false,
);
export const DeleteTechnologyError = new BaseFeedback(
  'DeleteTechnologyError',
  false,
);

export const AttachResourceError = new BaseFeedback(
  'AttachResourceError',
  false,
);
export const AttachmentSizeError = new BaseFeedback(
  'AttachmentSizeError',
  false,
);

export const ExportDataError = new BaseFeedback('ExportDataError', false);

/** Positive Feedbacks */
export const CreateResourceSuccess = new BaseFeedback(
  'CreateResourceSuccess',
  true,
);
export const UpdateResourceSuccess = new BaseFeedback(
  'UpdateResourceSuccess',
  true,
);
export const DeleteResourceSuccess = new BaseFeedback(
  'DeleteResourceSuccess',
  true,
);

export const CreateInsuranceSuccess = new BaseFeedback(
  'CreateInsuranceSuccess',
  true,
);
export const UpdateInsuranceSuccess = new BaseFeedback(
  'UpdateInsuranceSuccess',
  true,
);
export const DeleteInsuranceSuccess = new BaseFeedback(
  'DeleteInsuranceSuccess',
  true,
);

export const createHandoverProtocolSuccess = new BaseFeedback(
  'createHandoverProtocolSuccess',
  true,
);

export const createReturnProtocolSuccess = new BaseFeedback(
  'createReturnProtocolSuccess',
  true,
);

export const CreateTransportSuccess = new BaseFeedback(
  'CreateTransportSuccess',
  true,
);
export const UpdateTransportSuccess = new BaseFeedback(
  'UpdateTransportSuccess',
  true,
);
export const DeleteTransportSuccess = new BaseFeedback(
  'DeleteTransportSuccess',
  true,
);

export const CreateServiceSuccess = new BaseFeedback(
  'CreateServiceSuccess',
  true,
);
export const UpdateServiceSuccess = new BaseFeedback(
  'UpdateServiceSuccess',
  true,
);
export const DeleteServiceSuccess = new BaseFeedback(
  'DeleteServiceSuccess',
  true,
);

export const CreateCategorySuccess = new BaseFeedback(
  'CreateCategorySuccess',
  true,
);
export const UpdateCategorySuccess = new BaseFeedback(
  'UpdateCategorySuccess',
  true,
);
export const DeleteCategorySuccess = new BaseFeedback(
  'DeleteCategorySuccess',
  true,
);

export const CreateProducerSuccess = new BaseFeedback(
  'CreateProducerSuccess',
  true,
);
export const UpdateProducerSuccess = new BaseFeedback(
  'UpdateProducerSuccess',
  true,
);
export const DeleteProducerSuccess = new BaseFeedback(
  'DeleteProducerSuccess',
  true,
);

export const CreateProducerModelSuccess = new BaseFeedback(
  'CreateProducerModelSuccess',
  true,
);
export const UpdateProducerModelSuccess = new BaseFeedback(
  'UpdateProducerModelSuccess',
  true,
);
export const DeleteProducerModelSuccess = new BaseFeedback(
  'DeleteProducerModelSuccess',
  true,
);

export const CreateMachineSuccess = new BaseFeedback(
  'CreateMachineSuccess',
  true,
);
export const UpdateMachineSuccess = new BaseFeedback(
  'UpdateMachineSuccess',
  true,
);
export const DeleteMachineSuccess = new BaseFeedback(
  'DeleteMachineSuccess',
  true,
);

export const CreateEquipmentSuccess = new BaseFeedback(
  'CreateEquipmentSuccess',
  true,
);
export const UpdateEquipmentSuccess = new BaseFeedback(
  'UpdateEquipmentSuccess',
  true,
);
export const DeleteEquipmentSuccess = new BaseFeedback(
  'DeleteEquipmentSuccess',
  true,
);

export const CreateMachineReportSuccess = new BaseFeedback(
  'CreateMachineReportSuccess',
  true,
);
export const CreateMultipleMachineReportsSuccess = new BaseFeedback(
  'CreateMultipleMachineReportsSuccess',
  true,
);
export const UpdateMachineReportSuccess = new BaseFeedback(
  'UpdateMachineReportSuccess',
  true,
);
export const DeleteMachineReportSuccess = new BaseFeedback(
  'DeleteMachineReportSuccess',
  true,
);

export const AssignMachineToolSuccess = new BaseFeedback(
  'AssignMachineToolSuccess',
  true,
);
export const UnassignMachineToolSuccess = new BaseFeedback(
  'UnassignMachineToolSuccess',
  true,
);

export const CreateTechnologySuccess = new BaseFeedback(
  'CreateTechnologySuccess',
  true,
);
export const UpdateTechnologySuccess = new BaseFeedback(
  'UpdateTechnologySuccess',
  true,
);
export const DeleteTechnologySuccess = new BaseFeedback(
  'DeleteTechnologySuccess',
  true,
);

export const AttachResourceSuccess = new BaseFeedback(
  'AttachResourceSuccess',
  true,
);

export const ExportDataSuccess = new BaseFeedback('ExportDataSuccess', true);

/**
 * Experimental object for testing purposes
 */
export const baseFeedback = {
  resource: {
    machine: {
      delete: {
        success: DeleteMachineSuccess,
        error: DeleteMachineError,
      },
      create: {
        success: CreateMachineSuccess,
        error: CreateMachineError,
      },
    },
  },
};
