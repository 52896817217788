<template>
  <div class="widget-card-controls">
    <b-button-group>

      <vs-button
        vs-variant="light"
        @click.prevent="$emit('edit')"
        class="edit-button"
        v-if="can(editPermission) && !hideEdit"
      >
        <feather-icon class="icon" icon="Edit2Icon" />
        Edytuj
      </vs-button>

      <vs-button
        vs-variant="light"
        @click.prevent="$emit('delete')"
        v-if="can(deletePermission) && !securedItems.length"
      >
        <feather-icon icon="Trash2Icon" />
      </vs-button>

      <b-dropdown
        id="dropdown"
        class="widget-card-dropdown"
        v-if="securedItems.length > 0"
        no-caret
        >

        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="18"
          />
        </template>

        <b-dropdown-item
          v-for="({ title, icon, callback }, idx) in securedItems" :key="idx"
          class="action-item"
          @click.stop="() => callback()"
        >
          <div class="action-item-content">

            <div class="icon">
              <feather-icon
                size="20"
                :icon="icon"
              />
            </div>

            <div class="text">
              {{ title }}
            </div>

          </div>
        </b-dropdown-item>

        <b-dropdown-item
          @click="$emit('delete')"
        >
          <div class="action-item-content">

            <div class="icon">
              <feather-icon
                size="20"
                icon="Trash2Icon"
              />
            </div>

            <div class="text">
              Usuń
            </div>

          </div>
        </b-dropdown-item>

      </b-dropdown>

    </b-button-group>
  </div>
</template>

<script>
import { BButtonGroup, BDropdown, BDropdownItem } from 'bootstrap-vue';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import store from '@/store';
import { computed } from 'vue';

export default {
  name: 'WidgetCardControls',
  emits: ['edit', 'delete'],
  props: {
    editPermission: {
      type: String,
    },
    hideEdit: {
      type: Boolean,
      default: false,
    },
    deletePermission: {
      type: String,
    },
    dropdownItems: {
      type: Array,
      default: () => ([]),
    },
  },
  setup(props) {
    const can = (permission) => {
      if (!permission) return true;
      const split = permission.split('.');
      const parent = `${split[0]}.${split[1]}`;
      const action = split[2];

      return store
        .getters['auth/userHasPermission'](action, parent);
    };

    const securedItems = computed(() => props.dropdownItems
      .filter((item) => {
        if (item.category && item.category.be_reported !== true) return false;
        if (!item.requiresPermission) return true;
        const perm = item.requiresPermission.split('.');

        return store.getters['auth/userHasPermission'](perm[2], `${perm[0]}.${perm[1]}`);
      }));

    return { can, securedItems };
  },
  components: {
    BButtonGroup,
    VsButton,
    BDropdown,
    BDropdownItem,
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables/_variables';

.widget-card-controls {
  .edit-button {
    .icon {
      margin-right: 10px
    }
  }

  .action-item-content {
    display: flex;
    align-items: center;

    .icon {
      margin-right: 7px;
    }
  }
}

#dropdown::v-deep {
  --background: rgba(230, 230, 230);
  --foreground: rgb(100, 100, 100) !important;
  --hover-shadow: rgba(230, 230, 230, 0.5);
  padding-left: 1px;

  button.dropdown-toggle {
    background-color: var(--background) !important;
    border-color: var(--background) !important;
    border-left-color: rgba(black, 0.08)!important;
    color: var(--foreground) !important;

    &:hover {
      box-shadow: 0 5px 10px var(--hover-shadow);
    }

    &::after {
      color: var(--foreground) !important;
    }
  }
}

.dark-layout {
  #dropdown::v-deep {
    --dark-background: #{lighten($theme-dark-card-bg, 5%)};
    --dark-foreground: #{lighten($theme-dark-card-bg, 45%)};
    --dark-hover-shadow: #{lighten($theme-dark-card-bg, 3%)};

    button.dropdown-toggle {
      background-color: var(--dark-background) !important;
      border-color: var(--dark-background) !important;
      border-left-color: rgba(black, 0.08)!important;
      color: var(--dark-foreground) !important;

      &:hover {
        box-shadow: 0 5px 10px var(--dark-hover-shadow);
      }

      &::after {
        color: var(--dark-foreground) !important;
      }
    }
  }
}
</style>
