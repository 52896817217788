var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "widget-card-controls"
  }, [_c('b-button-group', [_vm.can(_vm.editPermission) && !_vm.hideEdit ? _c('vs-button', {
    staticClass: "edit-button",
    attrs: {
      "vs-variant": "light"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.$emit('edit');
      }
    }
  }, [_c('feather-icon', {
    staticClass: "icon",
    attrs: {
      "icon": "Edit2Icon"
    }
  }), _vm._v(" Edytuj ")], 1) : _vm._e(), _vm.can(_vm.deletePermission) && !_vm.securedItems.length ? _c('vs-button', {
    attrs: {
      "vs-variant": "light"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.$emit('delete');
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "Trash2Icon"
    }
  })], 1) : _vm._e(), _vm.securedItems.length > 0 ? _c('b-dropdown', {
    staticClass: "widget-card-dropdown",
    attrs: {
      "id": "dropdown",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('feather-icon', {
          attrs: {
            "icon": "MoreVerticalIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }], null, false, 3186786308)
  }, [_vm._l(_vm.securedItems, function (_ref, idx) {
    var title = _ref.title,
      icon = _ref.icon,
      callback = _ref.callback;
    return _c('b-dropdown-item', {
      key: idx,
      staticClass: "action-item",
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return function () {
            return callback();
          }.apply(null, arguments);
        }
      }
    }, [_c('div', {
      staticClass: "action-item-content"
    }, [_c('div', {
      staticClass: "icon"
    }, [_c('feather-icon', {
      attrs: {
        "size": "20",
        "icon": icon
      }
    })], 1), _c('div', {
      staticClass: "text"
    }, [_vm._v(" " + _vm._s(title) + " ")])])]);
  }), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.$emit('delete');
      }
    }
  }, [_c('div', {
    staticClass: "action-item-content"
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('feather-icon', {
    attrs: {
      "size": "20",
      "icon": "Trash2Icon"
    }
  })], 1), _c('div', {
    staticClass: "text"
  }, [_vm._v(" Usuń ")])])])], 2) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }