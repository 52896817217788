export default {
  /* Errors */
  CreateResourceError: {
    title: 'Nie udało się dodać zasobu',
    description: 'Popraw dane w formularzu',
  },

  UpdateResourceError: {
    title: 'Nie udało się zaktualizować zasobu',
    description: 'Popraw dane w formularzu',
  },

  DeleteResourceError: {
    title: 'Nie udało się usunąć zasobu',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  CreateInsuranceError: {
    title: 'Nie udało się dodać ubezpieczenia',
    description: 'Popraw dane w formularzu',
  },

  UpdateInsuranceError: {
    title: 'Nie udało się zaktualizować ubezpieczenia',
    description: 'Popraw dane w formularzu',
  },

  DeleteInsuranceError: {
    title: 'Nie udało się usunąć ubezpieczenia',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  createHandoverProtocolError: {
    title: 'Nie udało się wygenerować dokumentu',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  createReturnProtocolError: {
    title: 'Nie udało się wygenerować dokumentu',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  CreateTransportError: {
    title: 'Nie udało się dodać transportu',
    description: 'Popraw dane w formularzu',
  },

  UpdateTransportError: {
    title: 'Nie udało się zaktualizować transportu',
    description: 'Popraw dane w formularzu',
  },

  DeleteTransportError: {
    title: 'Nie udało się usunąć transportu',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  FetchTransportError: {
    title: 'Nie udało się pobrać informacji o transporcie',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  CreateServiceError: {
    title: 'Nie udało się dodać serwisu',
    description: 'Popraw dane w formularzu',
  },

  UpdateServiceError: {
    title: 'Nie udało się zaktualizować serwisu',
    description: 'Popraw dane w formularzu',
  },

  DeleteServiceError: {
    title: 'Nie udało się usunąć serwisu',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  FetchServiceError: {
    title: 'Nie udało się pobrać informacji o serwisie',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  CreateCategoryError: {
    title: 'Nie udało się dodać kategorii',
    description: 'Popraw dane w formularzu',
  },

  UpdateCategoryError: {
    title: 'Nie udało się zaktualizować kategorii',
    description: 'Popraw dane w formularzu',
  },

  DeleteCategoryError: {
    title: 'Nie udało się usunąć kategorii',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  CreateProducerError: {
    title: 'Nie udało się dodać producenta',
    description: 'Popraw dane w formularzu',
  },

  UpdateProducerError: {
    title: 'Nie udało się zaktualizować producenta',
    description: 'Popraw dane w formularzu',
  },

  DeleteProducerError: {
    title: 'Nie udało się usunąć producenta',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  CreateProducerModelError: {
    title: 'Nie udało się dodać modelu',
    description: 'Popraw dane w formularzu',
  },

  UpdateProducerModelError: {
    title: 'Nie udało się zaktualizować modelu',
    description: 'Popraw dane w formularzu',
  },

  DeleteProducerModelError: {
    title: 'Nie udało się usunąć modelu',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  CreateMachineError: {
    title: 'Nie udało się dodać maszyny',
    description: 'Popraw dane w formularzu',
  },

  UpdateMachineError: {
    title: 'Nie udało się zaktualizować maszyny',
    description: 'Popraw dane w formularzu',
  },

  DeleteMachineError: {
    title: 'Nie udało się usunąć maszyny',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  FetchPickerMachinesError: {
    title: 'Nie udało się pobrać listy maszyn',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  CreateEquipmentError: {
    title: 'Nie udało się dodać sprzętu',
    description: 'Popraw dane w formularzu',
  },

  UpdateEquipmentError: {
    title: 'Nie udało się zaktualizować sprzętu',
    description: 'Popraw dane w formularzu',
  },

  DeleteEquipmentError: {
    title: 'Nie udało się usunąć sprzętu',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  FetchStatusesError: {
    title: 'Nie udało się pobrać listy statusów',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  FetchCategoriesError: {
    title: 'Nie udało się pobrać listy kategorii',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  FetchTechnologiesError: {
    title: 'Nie udało się pobrać listy technologii',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  FetchProducersModelsError: {
    title: 'Nie udało się pobrać listy producentów',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  FetchProducersError: {
    title: 'Nie udało się pobrać listy producentów',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  FetchGroupsError: {
    title: 'Nie udało się pobrać listy grup',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  FetchWorkTypesError: {
    title: 'Nie udało się pobrać listy typów',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  CreateMachineReportError: {
    title: 'Nie udało się utworzyć raportu dla zasobu',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  CreateMultipleMachineReportsError: {
    title: 'Nie udało się utworzyć raportów dla zasobów',
    description: 'Popraw wysyłane dane i spróbuj ponownie',
  },

  UpdateMachineReportError: {
    title: 'Nie udało się zaktualizować raportu zasobu',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  DeleteMachineReportError: {
    title: 'Nie udało się usunąć raportu zasobu',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  AssignMachineToolError: {
    title: 'Nie udało się przypisać sprzętu do maszyny',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  UnassignMachineToolError: {
    title: 'Nie udało się usunąć sprzętu z maszyny',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  CreateTechnologyError: {
    title: 'Nie udało się utworzyć technologii',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  UpdateTechnologyError: {
    title: 'Nie udało się zaktualizować technologii',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  DeleteTechnologyError: {
    title: 'Nie udało się usunąć technologii',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  AttachResourceError: {
    title: 'Nie udało się przypisać zasobów',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  AttachmentSizeError: {
    title: 'Załącznik jest zbyt duży',
    description: 'Wielkość załącznika nie może przekraczać 60 MB',
  },

  ExportDataError: {
    title: 'Wystąpił błąd podczas eksportu danych',
    description: 'Spróbuj ponownie później',
  },

  /* Success */
  CreateResourceSuccess: {
    title: 'Pomyślnie dodano zasób',
    description: '{name}',
  },

  UpdateResourceSuccess: {
    title: 'Pomyślnie zaktualizowano zasób',
    description: '{name}',
  },

  DeleteResourceSuccess: {
    title: 'Pomyślnie usunięto zasób',
    description: '{name}',
  },

  CreateInsuranceSuccess: {
    title: 'Pomyślnie dodano ubezpieczenie',
    description: '{name}',
  },

  UpdateInsuranceSuccess: {
    title: 'Pomyślnie zaktualizowano ubezpieczenie',
    description: '{policy}',
  },

  DeleteInsuranceSuccess: {
    title: 'Pomyślnie usunięto ubezpieczenie',
    description: '{policy}',
  },

  createHandoverProtocolSuccess: {
    title: 'Pomyślnie wygenerowano dokument',
  },

  createReturnProtocolSuccess: {
    title: 'Pomyślnie wygenerowano dokument',
  },

  CreateTransportSuccess: {
    title: 'Pomyślnie dodano transport',
    description: '{name}',
  },

  UpdateTransportSuccess: {
    title: 'Pomyślnie zaktualizowano transport',
    description: '{name}',
  },

  DeleteTransportSuccess: {
    title: 'Pomyślnie usunięto transport',
    description: '{name}',
  },

  CreateServiceSuccess: {
    title: 'Pomyślnie dodano serwis',
  },

  UpdateServiceSuccess: {
    title: 'Pomyślnie zaktualizowano serwis',
  },

  DeleteServiceSuccess: {
    title: 'Pomyślnie usunięto serwis',
  },

  CreateCategorySuccess: {
    title: 'Pomyślnie dodano kategorię',
    description: '{policy}',
  },

  UpdateCategorySuccess: {
    title: 'Pomyślnie zaktualizowano kategorię',
    description: '{name}',
  },

  DeleteCategorySuccess: {
    title: 'Pomyślnie usunięto kategorię',
    description: '{name}',
  },

  CreateProducerSuccess: {
    title: 'Pomyślnie dodano producenta',
    description: '{name}',
  },

  UpdateProducerSuccess: {
    title: 'Pomyślnie zaktualizowano producenta',
    description: '{name}',
  },

  DeleteProducerSuccess: {
    title: 'Pomyślnie usunięto producenta',
    description: '{name}',
  },

  CreateProducerModelSuccess: {
    title: 'Pomyślnie dodano model',
    description: '{name}',
  },

  UpdateProducerModelSuccess: {
    title: 'Pomyślnie zaktualizowano model',
    description: '{name}',
  },

  DeleteProducerModelSuccess: {
    title: 'Pomyślnie usunięto model',
    description: '{name}',
  },

  CreateMachineSuccess: {
    title: 'Pomyślnie dodano maszynę',
    description: '{name}',
    action: 'Przejdź do szczegółow maszyny',
  },

  UpdateMachineSuccess: {
    title: 'Pomyślnie zaktualizowano maszynę',
    description: '{name}',
  },

  DeleteMachineSuccess: {
    title: 'Pomyślnie usunięto maszynę',
    description: '{name}',
  },

  CreateEquipmentSuccess: {
    title: 'Pomyślnie dodano sprzęt',
    description: '{name}',
    action: 'Przejdź do szczegółow sprzętu',
  },

  UpdateEquipmentSuccess: {
    title: 'Pomyślnie zaktualizowano sprzęt',
    description: '{name}',
  },

  DeleteEquipmentSuccess: {
    title: 'Pomyślnie usunięto sprzęt',
    description: '{name}',
  },

  CreateMachineReportSuccess: {
    title: 'Pomyślnie utworzono raport zasobu',
    description: '{name}',
  },

  CreateMultipleMachineReportsSuccess: {
    title: 'Pomyślnie utworzono raporty dla zasobów',
  },

  UpdateMachineReportSuccess: {
    title: 'Pomyślnie zaktualizowano raport zasobu',
    description: '{name}',
  },

  DeleteMachineReportSuccess: {
    title: 'Pomyślnie usunięto raport zasobu',
    description: 'z dnia {date}',
  },

  AssignMachineToolSuccess: {
    title: 'Pomyślnie przypisano sprzęt do maszyny',
    description: '',
  },

  UnassignMachineToolSuccess: {
    title: 'Pomyślnie usunięto przypisany sprzęt z maszyny',
    description: '{name}',
  },

  CreateTechnologySuccess: {
    title: 'Pomyślnie utworzono technologię',
    description: '{name}',
  },

  UpdateTechnologySuccess: {
    title: 'Pomyślnie zaktualizowano technologię',
    description: '{name}',
  },

  DeleteTechnologySuccess: {
    title: 'Pomyślnie usunięto technologię',
    description: '{name}',
  },

  AttachResourceSuccess: {
    title: 'Pomyślnie przypisano zasoby',
  },

  ExportDataSuccess: {
    title: 'Pomyślnie wyeksportowano dane',
  },
};
